input{
    margin: 0 20px 0 0 ;
}

.info-add{
    width: 100%;
    
}

label{
    font-size:17px;
    margin: 5px 10px 0 10px;
    font-weight:500 !important;
}

